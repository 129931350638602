import React from "react";

const IncompatibleComponent = () => {
	return (
		<div className="incomp">
			Your browser is not supported. Try updating your browser or using a
			different one.
		</div>
	);
};
export default IncompatibleComponent;
